import { Url } from "../../../domain/model/Auth";
import { Response } from "../../api/Response";
import NetworkApi from "../../network/NetworkApi";
import { UploadImageRepository } from "./UploadImageRepository";

export class UploadImageRepositoryImpl implements UploadImageRepository {
  apiService: NetworkApi;

  constructor(datasource: NetworkApi) {
    this.apiService = datasource;
  }
  uploadImage(imageFormData: FormData): Promise<Response<Url>> {
    return this.apiService.uploadImage(imageFormData);
  }
}
