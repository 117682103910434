import { Card, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { SectionView } from "../../../component/Section";
import LinesEllipsis from "react-lines-ellipsis";
import { FIGTREE_FONT_FAMILY } from "../../../../data/constant/Constants";
import { COLOR_LIGHT_GRAY } from "../../../../data/colors/Colors";
import { PreviousMessagesViewModel } from "./PreviousMessagesViewModel";
import { provideGetAllAndroidTvMessagesUseCase } from "../../../di/UseCasesModule";
import { formateDate } from "../../../../core/utils/Utils";
import {
  STRINGS_PREVIOUS_MESSAGES,
  STRINGS_YOU_CAN_SEE_PREVIOUS_MESSAGE_HERE,
} from "../../../../data/strings/Strings";

export function PreviousMessagesView() {
  const viewModel = PreviousMessagesViewModel(
    provideGetAllAndroidTvMessagesUseCase()
  );

  return (
    <SectionView
      title={STRINGS_PREVIOUS_MESSAGES}
      subtitle={STRINGS_YOU_CAN_SEE_PREVIOUS_MESSAGE_HERE}
      minHeight="28vh"
      layout={
        <Grid container spacing={2} marginTop={0}>
          {viewModel.isLoading ? (
            <>
              {Array.from(Array(3).keys()).map((i) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    xl={4}
                    height={"20vh"}
                    key={i}
                  >
                    <Skeleton
                      variant="rounded"
                      height={"100%"}
                      sx={{ borderRadius: 4 }}
                    />
                  </Grid>
                );
              })}
            </>
          ) : (
            <>
              {viewModel.messages.map((androidTvMessage) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    xl={4}
                    height={"20vh"}
                    key={androidTvMessage._id}
                  >
                    <Card
                      elevation={2}
                      sx={{ borderRadius: 4, padding: 2, height: "18vh" }}
                    >
                      <Stack
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        display={"flex"}
                        height={"100%"}
                      >
                        <LinesEllipsis
                          text={`“${androidTvMessage.message}”`}
                          maxLine="3"
                          ellipsis=" ..."
                          trimRight
                          basedOn="words"
                          style={{
                            fontFamily: FIGTREE_FONT_FAMILY,
                            fontSize: 18,
                            fontWeight: "600",
                          }}
                        />
                        <Typography
                          fontFamily={FIGTREE_FONT_FAMILY}
                          color={COLOR_LIGHT_GRAY}
                          fontSize={16}
                        >
                          {androidTvMessage.createdAt && (
                            <>
                              Sent at{" "}
                              {formateDate(
                                new Date(androidTvMessage.createdAt)
                              )}
                            </>
                          )}
                        </Typography>
                      </Stack>
                    </Card>
                  </Grid>
                );
              })}
            </>
          )}
        </Grid>
      }
    />
  );
}
