import { Box, CircularProgress, Stack } from "@mui/material";
import { CustomTextField } from "../addNubVisionApp/AddOrEditNubVisionAppView";
import { STRINGS_CURRENT_VERSION } from "../../../data/strings/Strings";

interface Props {
  handleCurrentVersionChanged: (version: string) => void;
  currentVersion: string | undefined;
  loading: boolean;
}

export function UpdateCurrentLauncherAppVersionView(props: Props) {
  const height = "25vh";

  return (
    <Box
      height={height}
      alignItems={"center"}
      justifyContent={"center"}
      display={"flex"}
      width={"100%"}
      padding={10}
    >
      {!props.loading ? (
        <CustomTextField
          handleChange={(txt) => props.handleCurrentVersionChanged(txt)}
          value={props.currentVersion}
          placeholder={STRINGS_CURRENT_VERSION}
          type="text"
        />
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
}
