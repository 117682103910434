import { Close } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  LinearProgress,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { FIGTREE_FONT_FAMILY } from "../../../data/constant/Constants";
import { COLOR_BLACK } from "../../../data/colors/Colors";
import { CustomAlertDialog } from "../../component/CustomAlertDialog";
import { STRINGS_CANCEl, STRINGS_CANCEl_DESRIPTION } from "../../../data/strings/Strings";

interface BottomDrawerControls {
  open: boolean;
  title: string;
  layout?: React.ReactElement | null;
  isLoading: boolean;
  handleLoading: (loading: boolean) => void;
  primaryActionButtonTitle: string;
  secondaryActionButtonTitle?: string | null;
  primaryActionButtonOnClick: () => void;
  secondaryActionButtonOnClick?: () => void;
  handleClose: () => void;
}

export const SectionBottomDrawerView: React.FC<BottomDrawerControls> = ({
  open,
  title,
  layout,
  isLoading = false,
  handleLoading,
  primaryActionButtonTitle,
  primaryActionButtonOnClick,
  secondaryActionButtonTitle = null,
  secondaryActionButtonOnClick = () => {},
  handleClose,
}: BottomDrawerControls) => {
  const [openAlertDialog, setOpenAlertDialog] = useState(false);

  return (
    <Drawer
      anchor={"bottom"}
      open={open}
      onClose={() => {
        if (isLoading) {
          setOpenAlertDialog(true);
          return;
        }
        handleClose();
      }}
      PaperProps={{
        style: { borderTopRightRadius: 24, borderTopLeftRadius: 24 },
      }}
    >
      <Box>
        <AppBar sx={{ position: "relative", background: COLOR_BLACK }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                if (isLoading) {
                  setOpenAlertDialog(true);
                  return;
                }
                handleClose();
              }}
              aria-label="close"
            >
              <Close />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
              fontFamily={FIGTREE_FONT_FAMILY}
            >
              {title}
            </Typography>

            {secondaryActionButtonTitle && (
              <Button
                onClick={secondaryActionButtonOnClick}
                variant="outlined"
                color="error"
                disabled={isLoading}
                sx={{ borderRadius: 12, marginRight: 2 }}
              >
                <Typography
                  variant="h6"
                  textTransform={"none"}
                  fontFamily={FIGTREE_FONT_FAMILY}
                >
                  {secondaryActionButtonTitle}
                </Typography>
              </Button>
            )}
            <Button
              onClick={primaryActionButtonOnClick}
              variant="outlined"
              disabled={isLoading}
              sx={{ borderRadius: 12 }}
            >
              <Typography
                variant="h6"
                textTransform={"none"}
                fontFamily={FIGTREE_FONT_FAMILY}
              >
                {primaryActionButtonTitle}
              </Typography>
            </Button>
          </Toolbar>
        </AppBar>
        {isLoading ? (
          <LinearProgress sx={{ height: "0.8vh" }} />
        ) : (
          <Divider sx={{ height: "0.2vh" }} />
        )}
        {layout}
      </Box>

      <CustomAlertDialog
        title={STRINGS_CANCEl}
        description={STRINGS_CANCEl_DESRIPTION}
        open={openAlertDialog}
        handleNegativeButtonClick={() => setOpenAlertDialog(false)}
        handlePositiveButtonClick={() => {
          setOpenAlertDialog(false);
          handleLoading(false);
          handleClose();

          // stopping all the requests
          window.stop();
        }}
      />
    </Drawer>
  );
};
