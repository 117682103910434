import { Typography } from "@mui/material";
import { FIGTREE_FONT_FAMILY } from "../../../data/constant/Constants";
import date from "date-and-time";
import { useEffect, useState } from "react";
import { COLOR_WHITE } from "../../../data/colors/Colors";
import { formateDate } from "../../../core/utils/Utils";

export function ClockView() {
  const [dateTime, setDateTime] = useState(getDateTime());

  useEffect(() => {
    setInterval(function () {
      setDateTime(getDateTime());
    }, 60 * 1000);
  }, []);

  function getDateTime(): string {
    const now = new Date();
    const formattedDateTime = formateDate(now);
    return formattedDateTime;
  }
  return (
    <Typography
      fontFamily={FIGTREE_FONT_FAMILY}
      fontWeight={"600"}
      color={COLOR_WHITE}
      fontSize={"24px"}
    >
      {dateTime}
    </Typography>
  );
}
