import NotistackWrapper from "./presentation/component/NotistackWrapper";
import Router from "./presentation/routes/Router";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import '@fontsource/figtree/300.css';
import '@fontsource/figtree/400.css';
import '@fontsource/figtree/500.css';
import '@fontsource/figtree/600.css';
import '@fontsource/figtree/700.css';

const theme = createTheme({
  typography: {
    fontFamily: 'Figtree',
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NotistackWrapper>
        <Router />
      </NotistackWrapper>
    </ThemeProvider>
  );
}

export default App;
