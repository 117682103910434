import { Box, Card, Grid, TextField } from "@mui/material";
import { CustomImageView } from "../../component/CustomImageView";
import { ImagePicker } from "../../component/ImagePicker";
import { HTMLInputTypeAttribute, useEffect, useState } from "react";
import { COLOR_WHITE } from "../../../data/colors/Colors";
import { SectionView } from "../../component/Section";
import { FIGTREE_FONT_FAMILY } from "../../../data/constant/Constants";
import {
  STRINGS_APP_APK_DOWNLOAD_URL,
  STRINGS_PACKAGE_ID,
  STRINGS_THE_DOWNLOAD_URL_USE_DOWNLOAD_APK,
  STRINGS_THIS_INFO_NEEDED_CREATING_NUB_VISION_APP,
  STRINGS_TITLE_NEEDED_FOR_NUB_VISION_APP,
  STRINGS_TYPE_A_TITLE,
} from "../../../data/strings/Strings";
import { NubVisionApp } from "../../../domain/model/NubVisionApp";

interface Props {
  selectedNubVision?: NubVisionApp | null;
  handleNubVisionAppCreated: (nubVisionApp: NubVisionApp) => void;
}

export function AddOrEditNubVisionAppView(props: Props) {
  const imageCardWidth = { xs: "94vw", sm: "96vw", md: "31vw", lg: "32vw" };
  const imageCardHeight = "36vh";

  const [nubVisionApp, setNubVisionApp] = useState<NubVisionApp | null>(null);

  useEffect(() => {
    if (props.selectedNubVision) {
      setNubVisionApp(props.selectedNubVision);
    }
  }, []);

  useEffect(() => {
    if (nubVisionApp) props.handleNubVisionAppCreated(nubVisionApp);
  }, [nubVisionApp]);

  return (
    <Box minHeight={"40vh"} padding={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} xl={4}>
          <Card
            elevation={0}
            sx={{
              borderRadius: 4,
              width: imageCardWidth,
              height: imageCardHeight,
            }}
          >
            <Box
              zIndex={"1"}
              position={"absolute"}
              sx={{
                width: imageCardWidth,
                height: imageCardHeight,
                borderRadius: 4,
              }}
            >
              <CustomImageView
                width="100%"
                height="100%"
                src={
                  nubVisionApp?.image ??
                  require("../../../assets/images/placeholder.png")
                }
                props={{
                  borderRadius: 4,
                }}
              />
            </Box>
            <Box
              zIndex={"2"}
              position={"absolute"}
              sx={{ background: COLOR_WHITE, borderRadius: 4, margin: 2 }}
            >
              <ImagePicker
                size={"small"}
                handleImagePicked={(file: File) => {
                  if (!file) return;
                  setNubVisionApp({
                    ...nubVisionApp,
                    image: URL.createObjectURL(file),
                    imageFile: file,
                  });
                }}
              />
            </Box>
          </Card>
        </Grid>

        <Grid container item xs={12} sm={12} md={8} xl={8} spacing={2}>
          <Grid item minHeight={imageCardHeight} xs={12} sm={12} md={6} xl={4}>
            <SectionView
              title={STRINGS_TYPE_A_TITLE}
              subtitle={STRINGS_TITLE_NEEDED_FOR_NUB_VISION_APP}
              layout={
                <CustomTextField
                  value={nubVisionApp?.title}
                  placeholder="Title"
                  handleChange={(txt) => {
                    setNubVisionApp({ ...nubVisionApp, title: txt });
                  }}
                />
              }
              minHeight="29vh"
            />
          </Grid>
          <Grid item minHeight={imageCardHeight} xs={12} sm={12} md={6} xl={4}>
            <SectionView
              title={`Type the ${STRINGS_PACKAGE_ID}`}
              subtitle={STRINGS_THIS_INFO_NEEDED_CREATING_NUB_VISION_APP}
              layout={
                <CustomTextField
                  value={nubVisionApp?.packageId}
                  placeholder={STRINGS_PACKAGE_ID}
                  handleChange={(txt) => {
                    setNubVisionApp({ ...nubVisionApp, packageId: txt });
                  }}
                />
              }
              minHeight="29vh"
            />
          </Grid>
          <Grid item minHeight={"36vh"} xs={12} sm={12} md={6} xl={4}>
            <SectionView
              title={`Type the ${STRINGS_APP_APK_DOWNLOAD_URL}`}
              subtitle={STRINGS_THE_DOWNLOAD_URL_USE_DOWNLOAD_APK}
              layout={
                <CustomTextField
                  value={nubVisionApp?.appApkDownloadUrl}
                  placeholder={STRINGS_APP_APK_DOWNLOAD_URL}
                  handleChange={(txt) => {
                    setNubVisionApp({
                      ...nubVisionApp,
                      appApkDownloadUrl: txt,
                    });
                  }}
                />
              }
              minHeight="29vh"
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

interface customTextFieldProps {
  value?: string;
  placeholder: string;
  isFullWidth?: boolean;
  handleChange: (txt: string) => void;
  type?: HTMLInputTypeAttribute;
}
export function CustomTextField(props: customTextFieldProps) {
  return (
    <TextField
      value={props.value ?? undefined}
      type={props.type ?? "text"}
      required
      autoComplete="off"
      fullWidth={props.isFullWidth}
      placeholder={`${props.placeholder} *`}
      InputProps={{
        style: {
          fontFamily: FIGTREE_FONT_FAMILY,
          borderRadius: 44,
        },
      }}
      onChange={(e) => props.handleChange(e.target.value)}
    />
  );
}
