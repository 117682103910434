import { useSnackbar } from "notistack";
import { useState } from "react";
import {
  STRINGS_PASSWORDS_ARE_NOT_MATCHING,
  STRINGS_PLEASE_FILL_OUT_ALL_INFO,
  STRINGS_YOU_NEED_TO_LOGIN_AGAIN,
} from "../../../data/strings/Strings";
import { ChangePasswordUseCase } from "../../../domain/usecase/auth/ChangePassword/ChangePasswordUseCase";
import { State } from "../../../domain/model/ResponseState";
import SharedPreferences from "../../../core/utils/SharedPreferences";

export function ChangePasswordViewModel(
  changePasswordUseCase: ChangePasswordUseCase
) {
  const { enqueueSnackbar } = useSnackbar();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setLoading] = useState(false);

  async function changePassword(handleClosePopover: () => void) {
    if (password.length === 0 || confirmPassword.length === 0) {
      showSnackbar(STRINGS_PLEASE_FILL_OUT_ALL_INFO);
      return;
    }
    if (password !== confirmPassword) {
      showSnackbar(STRINGS_PASSWORDS_ARE_NOT_MATCHING);
      return;
    }

    setLoading(true);

    const response = await changePasswordUseCase.invoke({
      password: password,
    });
    switch (response.responseState) {
      case State.Success:
        const message = response.data?.message;
        showSnackbar(message, "success");
        setTimeout(() => {
          showSnackbar(STRINGS_YOU_NEED_TO_LOGIN_AGAIN, "info");
          SharedPreferences.clear();
        }, 1500);
        handleClosePopover();
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }
    setLoading(false);
  }

  function showSnackbar(
    message: string | undefined,
    variant: "error" | "success" | "info" = "error"
  ) {
    if (message) enqueueSnackbar(message, { variant: variant });
  }

  return { setPassword, setConfirmPassword, changePassword, isLoading };
}
