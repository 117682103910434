import { Box, Button, CircularProgress, Grid, Stack } from "@mui/material";
import { SectionView } from "../../component/Section";
import { CustomTextField } from "../addHomeButton/AddOrEditHomeButtonView";
import { PreviousMessagesView } from "./previousMessages/PreviousMessagesView";
import { Send } from "@mui/icons-material";
import AutoHeight from "../../component/AutoHeightView";
import { SendMessageViewModel } from "./SendMessageViewModel";
import { provideSendAndroidTvMessageUseCase } from "../../di/UseCasesModule";
import {
  STRINGS_MESSAGE,
  STRINGS_SEND_MESSAGE,
  STRINGS_YOUR_MESSAGE_WILL_BE_SENT_ALL_USERS,
} from "../../../data/strings/Strings";

export function SendMessageView() {
  const viewModel = SendMessageViewModel(provideSendAndroidTvMessageUseCase());

  return (
    <AutoHeight>
      <Box
        padding={2}
        minHeight={"40vh"}
        display={"flex"}
        alignItems={"center"}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} xl={4}>
            <SectionView
              title={STRINGS_SEND_MESSAGE}
              subtitle={STRINGS_YOUR_MESSAGE_WILL_BE_SENT_ALL_USERS}
              minHeight="28vh"
              layout={
                <Box component="form" onSubmit={viewModel.sendMessage}>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <CustomTextField
                      handleChange={(txt) => {
                        viewModel.setMessage(txt);
                      }}
                      value={viewModel.message}
                      placeholder={STRINGS_MESSAGE}
                      isFullWidth
                    />
                    <Box>
                      <Button
                        sx={{ padding: 2, borderRadius: 22 }}
                        color="primary"
                        type="submit"
                        variant="outlined"
                        disabled={viewModel.isLoading}
                      >
                        {viewModel.isLoading ? (
                          <CircularProgress size={25} color="inherit" />
                        ) : (
                          <Send />
                        )}
                      </Button>
                    </Box>
                  </Stack>
                </Box>
              }
            />
          </Grid>

          <Grid item xs={12} sm={12} md={8} xl={8}>
            <PreviousMessagesView />
          </Grid>
        </Grid>
      </Box>
    </AutoHeight>
  );
}
