import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { HomeButton, HomeButtonAction } from "../../../domain/model/HomeButton";
import { UploadImageUseCase } from "../../../domain/usecase/uploadImage/UploadImageUseCase";
import { AndroidTVSettingsChangeBackgroundImageUseCase } from "../../../domain/usecase/androidTVSettings/changeBackgroundImage/ChangeBackgroundImageUseCase";
import { AndroidTVSettingsAddHomeButtonUseCase } from "../../../domain/usecase/androidTVSettings/addHomeButton/AddHomeButtonUseCase";
import { State } from "../../../domain/model/ResponseState";
import { AndroidTVSettingsDeleteHomeButtonUseCase } from "../../../domain/usecase/androidTVSettings/deleteHomeButton/DeleteHomeButtonUseCase";
import { AndroidTVSettingsUpdateHomeButtonUseCase } from "../../../domain/usecase/androidTVSettings/updateHomeButton/UpdateHomeButtonUseCase";
import {
  STRINGS_PLEASE_CHOOSE_AN_IMAGE_FIRST,
  STRINGS_PLEASE_FILL_OUT_ALL_INFO,
  STRINGS_TYPE_A_VERSION,
} from "../../../data/strings/Strings";
import { NubVisionApp } from "../../../domain/model/NubVisionApp";
import { AddNubVisionAppUseCase } from "../../../domain/usecase/nubVisionApps/add/AddNubVisionAppUseCase";
import { UpdateNubVisionAppUseCase } from "../../../domain/usecase/nubVisionApps/update/UpdateNubVisionAppUseCase";
import { DeleteNubVisionAppUseCase } from "../../../domain/usecase/nubVisionApps/delete/DeleteNubVisionAppUseCase";
import { AndroidTVSettingsUpdateCurrentLauncherAppVersionUseCase } from "../../../domain/usecase/androidTVSettings/updateCurrentLauncherAppVersion/UpdateCurrentLauncherAppVersionUseCase";
import { GetAndroidTVSettingsUseCase } from "../../../domain/usecase/androidTVSettings/get/GetAndroidTVSettingsUseCase";

export function HomeViewModel(
  uploadImageUseCase: UploadImageUseCase,
  changeBackgroundImageUseCase: AndroidTVSettingsChangeBackgroundImageUseCase,
  addHomeButtonUseCase: AndroidTVSettingsAddHomeButtonUseCase,
  androidTVSettingsDeleteHomeButtonUseCase: AndroidTVSettingsDeleteHomeButtonUseCase,
  updateHomeButtonUseCase: AndroidTVSettingsUpdateHomeButtonUseCase,
  addNubVisionAppUseCase: AddNubVisionAppUseCase,
  updateNubVisionAppUseCase: UpdateNubVisionAppUseCase,
  deleteNubVisionAppUseCase: DeleteNubVisionAppUseCase,
  androidTVSettingsUpdateCurrentLauncherAppVersionUseCase: AndroidTVSettingsUpdateCurrentLauncherAppVersionUseCase,
  getAndroidTvSettingsUseCase: GetAndroidTVSettingsUseCase
) {
  const { enqueueSnackbar } = useSnackbar();
  const [chosenBackgroundImageFile, setChosenBackgroundImageFile] =
    useState<File | null>(null);
  const [currentLauncherAppVersion, setCurrentLauncherAppVersion] = useState<
    string | undefined
  >("");
  const [
    currentLauncherAppVersionLoading,
    setCurrentLauncherAppVersionLoading,
  ] = useState(false);
  const [createdHomeButton, setCreatedHomeButton] = useState<HomeButton | null>(
    null
  );
  const [createdNubVisionApp, setCreatedNubVisionApp] =
    useState<NubVisionApp | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  function getLoading(): boolean {
    return isLoading;
  }

  function setLoading(loading: boolean) {
    setIsLoading(loading);
  }

  function handleChosenImageFile(imageFile: File) {
    setChosenBackgroundImageFile(imageFile);
  }

  function handleHomeButtonCreated(homeButton: HomeButton) {
    setCreatedHomeButton(homeButton);
  }

  function handleNubVisionAppCreated(nubVisionApp: NubVisionApp) {
    setCreatedNubVisionApp(nubVisionApp);
  }

  useEffect(() => {
    getCurrentLauncherAppVersion();
  }, []);

  async function getCurrentLauncherAppVersion() {
    setCurrentLauncherAppVersionLoading(true);
    const response = await getAndroidTvSettingsUseCase.invoke();
    switch (response.responseState) {
      case State.Success:
        setCurrentLauncherAppVersion(response.data?.currentLauncherAppVersion);
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }
    setCurrentLauncherAppVersionLoading(false);
  }

  async function uploadBackgroundImage(handleClose: () => void) {
    if (!chosenBackgroundImageFile) {
      showSnackbar(STRINGS_PLEASE_CHOOSE_AN_IMAGE_FIRST);
      return;
    }
    setIsLoading(true);

    const uploadImageUrl = await uploadImage(chosenBackgroundImageFile);

    if (!uploadImageUrl) {
      setIsLoading(false);
      return;
    }

    const response = await changeBackgroundImageUseCase.invoke({
      url: uploadImageUrl,
    });

    switch (response.responseState) {
      case State.Success:
        const message = response.data?.message;
        showSnackbar(message, "success");
        handleClose();
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }

    setIsLoading(false);
    setChosenBackgroundImageFile(null);
  }

  async function updateCurrentLauncherAppVersion(handleClose: () => void) {
    if (!currentLauncherAppVersion) {
      showSnackbar(STRINGS_TYPE_A_VERSION);
      return;
    }
    setIsLoading(true);

    const response =
      await androidTVSettingsUpdateCurrentLauncherAppVersionUseCase.invoke({
        currentVersion: currentLauncherAppVersion,
      });

    switch (response.responseState) {
      case State.Success:
        const message = response.data?.message;
        showSnackbar(message, "success");
        handleClose();
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }

    setIsLoading(false);
  }

  async function addHomeButton(handleClose: () => void) {
    if (
      !createdHomeButton ||
      !createdHomeButton.title ||
      !createdHomeButton.image ||
      !createdHomeButton.imageFile
    ) {
      showSnackbar(STRINGS_PLEASE_FILL_OUT_ALL_INFO);
      return;
    }
    if (
      createdHomeButton.action === HomeButtonAction.URL_REDIRECT &&
      !createdHomeButton.url
    ) {
      showSnackbar(STRINGS_PLEASE_FILL_OUT_ALL_INFO);
      return;
    }
    if (
      createdHomeButton.action === HomeButtonAction.CUSTOM_APP &&
      (!createdHomeButton.packageId || !createdHomeButton.appApkDownloadUrl)
    ) {
      showSnackbar(STRINGS_PLEASE_FILL_OUT_ALL_INFO);
      return;
    }
    setIsLoading(true);

    const uploadImageUrl = await uploadImage(createdHomeButton.imageFile);

    if (!uploadImageUrl) {
      setIsLoading(false);
      return;
    }

    createdHomeButton.image = uploadImageUrl;

    const response = await addHomeButtonUseCase.invoke(createdHomeButton);

    switch (response.responseState) {
      case State.Success:
        const message = response.data?.message;
        showSnackbar(message, "success");
        setCreatedHomeButton(null);
        handleClose();
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }

    setIsLoading(false);
  }

  async function deleteHomeButton(
    id: string | undefined,
    handleClose: () => void
  ) {
    if (!id) return;
    setLoading(true);
    const response = await androidTVSettingsDeleteHomeButtonUseCase.invoke(id);
    switch (response.responseState) {
      case State.Success:
        showSnackbar(response.data?.message, "success");
        handleClose();
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }
    setLoading(false);
  }

  async function updateHomeButton(handleClose: () => void) {
    if (
      !createdHomeButton ||
      !createdHomeButton.title ||
      !createdHomeButton.image
    ) {
      showSnackbar(STRINGS_PLEASE_FILL_OUT_ALL_INFO);
      return;
    }
    if (
      createdHomeButton.action === HomeButtonAction.URL_REDIRECT &&
      !createdHomeButton.url
    ) {
      showSnackbar(STRINGS_PLEASE_FILL_OUT_ALL_INFO);
      return;
    }
    if (
      createdHomeButton.action === HomeButtonAction.CUSTOM_APP &&
      (!createdHomeButton.packageId || !createdHomeButton.appApkDownloadUrl)
    ) {
      showSnackbar(STRINGS_PLEASE_FILL_OUT_ALL_INFO);
      return;
    }
    setIsLoading(true);

    if (createdHomeButton.imageFile) {
      const uploadImageUrl = await uploadImage(createdHomeButton.imageFile);
      if (!uploadImageUrl) {
        setIsLoading(false);
        return;
      }
      createdHomeButton.image = uploadImageUrl;
    }
    const response = await updateHomeButtonUseCase.invoke(createdHomeButton);

    switch (response.responseState) {
      case State.Success:
        const message = response.data?.message;
        showSnackbar(message, "success");
        setCreatedHomeButton(null);
        handleClose();
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }

    setIsLoading(false);
  }

  async function uploadImage(
    imageFile: File
  ): Promise<string | null | undefined> {
    const formData = new FormData();
    formData.append("image", imageFile);
    const uploadImageReponse = await uploadImageUseCase.invoke(formData);
    switch (uploadImageReponse.responseState) {
      case State.Success:
        const imageUrl: string | undefined = uploadImageReponse.data?.url;
        return imageUrl;
      case State.Fail:
      case State.Error:
        showSnackbar(uploadImageReponse.error?.message);
        return null;
    }
  }

  async function addNubVisionApp(handleClose: () => void) {
    if (
      !createdNubVisionApp ||
      !createdNubVisionApp.title ||
      !createdNubVisionApp.image ||
      !createdNubVisionApp.imageFile ||
      !createdNubVisionApp.packageId ||
      !createdNubVisionApp.appApkDownloadUrl
    ) {
      showSnackbar(STRINGS_PLEASE_FILL_OUT_ALL_INFO);
      return;
    }
    setIsLoading(true);

    const uploadImageUrl = await uploadImage(createdNubVisionApp.imageFile);

    if (!uploadImageUrl) {
      setIsLoading(false);
      return;
    }

    createdNubVisionApp.image = uploadImageUrl;

    const response = await addNubVisionAppUseCase.invoke(createdNubVisionApp);

    switch (response.responseState) {
      case State.Success:
        const message = response.data?.message;
        showSnackbar(message, "success");
        setCreatedNubVisionApp(null);
        handleClose();
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }

    setIsLoading(false);
  }

  async function deleteNubVisionApp(
    id: string | undefined,
    handleClose: () => void
  ) {
    if (!id) return;
    setLoading(true);
    const response = await deleteNubVisionAppUseCase.invoke(id);
    switch (response.responseState) {
      case State.Success:
        showSnackbar(response.data?.message, "success");
        handleClose();
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }
    setLoading(false);
  }

  async function updateNubVisionApp(handleClose: () => void) {
    if (
      !createdNubVisionApp ||
      !createdNubVisionApp.title ||
      !createdNubVisionApp.image ||
      !createdNubVisionApp.packageId ||
      !createdNubVisionApp.appApkDownloadUrl
    ) {
      showSnackbar(STRINGS_PLEASE_FILL_OUT_ALL_INFO);
      return;
    }
    setIsLoading(true);

    if (createdNubVisionApp.imageFile) {
      const uploadImageUrl = await uploadImage(createdNubVisionApp.imageFile);
      if (!uploadImageUrl) {
        setIsLoading(false);
        return;
      }
      createdNubVisionApp.image = uploadImageUrl;
    }
    const response = await updateNubVisionAppUseCase.invoke(
      createdNubVisionApp
    );

    switch (response.responseState) {
      case State.Success:
        const message = response.data?.message;
        showSnackbar(message, "success");
        setCreatedNubVisionApp(null);
        handleClose();
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }

    setIsLoading(false);
  }

  function showSnackbar(
    message: string | undefined,
    variant: "error" | "success" = "error"
  ) {
    if (message) enqueueSnackbar(message, { variant: variant });
  }

  return {
    uploadBackgroundImage,
    getLoading,
    setLoading,
    handleChosenImageFile,
    handleHomeButtonCreated,
    handleNubVisionAppCreated,
    addHomeButton,
    deleteHomeButton,
    updateHomeButton,
    addNubVisionApp,
    deleteNubVisionApp,
    updateNubVisionApp,
    updateCurrentLauncherAppVersion,
    currentLauncherAppVersion,
    setCurrentLauncherAppVersion,
    currentLauncherAppVersionLoading
  };
}
