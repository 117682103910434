import { UploadImageRepository } from "../../../data/repository/uploadImage/UploadImageRepository";
import { Url } from "../../model/Auth";
import { ResponseState, State } from "../../model/ResponseState";

export interface UploadImageUseCase {
  invoke: (imageFormData: FormData) => Promise<ResponseState<Url>>;
}

export class UploadImageUseCaseImpl implements UploadImageUseCase {
  private uploadImageRepository: UploadImageRepository;

  constructor(uploadImageRepository: UploadImageRepository) {
    this.uploadImageRepository = uploadImageRepository;
  }

  async invoke(imageFormData: FormData): Promise<ResponseState<Url>> {
    try {
      let response = await this.uploadImageRepository.uploadImage(
        imageFormData
      );
      if (response.isSuccessful) {
        return {
          responseState: State.Success,
          data: response.body!,
        };
      }
      return { responseState: State.Fail, error: response.errorBody! };
    } catch (error) {
      return {
        responseState: State.Error,
        error: { message: String(error), statusCode: 500 },
      };
    }
  }
}
