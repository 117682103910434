import { AndroidTVMessagesRepository } from "../../../../data/repository/androidTVMessages/AndroidTVMessagesRepository";
import { AndroidTVMessage } from "../../../model/AndroidTVMessage";
import { Message } from "../../../model/Auth";
import { ResponseState, State } from "../../../model/ResponseState";

export interface SendAndroidTVMessageUseCase {
  invoke: (message: AndroidTVMessage) => Promise<ResponseState<Message>>;
}

export class SendAndroidTVMessageUseCaseImpl
  implements SendAndroidTVMessageUseCase
{
  private androidTVMessagesRepository: AndroidTVMessagesRepository;

  constructor(androidTVMessagesRepository: AndroidTVMessagesRepository) {
    this.androidTVMessagesRepository = androidTVMessagesRepository;
  }

  async invoke(message: AndroidTVMessage): Promise<ResponseState<Message>> {
    try {
      let response = await this.androidTVMessagesRepository.sendMessage(
        message
      );
      if (response.isSuccessful) {
        return {
          responseState: State.Success,
          data: response.body!,
        };
      }
      return { responseState: State.Fail, error: response.errorBody! };
    } catch (error) {
      return {
        responseState: State.Error,
        error: { message: String(error), statusCode: 500 },
      };
    }
  }
}
