import { useSnackbar } from "notistack";
import SharedPreferences from "../../../core/utils/SharedPreferences";
import { STORAGE_KEYS } from "../../../data/constant/Constants";
import { LoginUseCase } from "../../../domain/usecase/auth/Login/LoginUseCase";
import { State } from "../../../domain/model/ResponseState";
import { useState } from "react";

export function LoginViewModel(loginUseCase: LoginUseCase) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  async function login(email: string, password: string) {
    setIsLoading(true);
    const response = await loginUseCase.invoke({ email, password });
    switch (response.responseState) {
      case State.Success:
        SharedPreferences.setString(
          STORAGE_KEYS.TOKEN,
          response.data?.accessToken!
        );
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }
    setIsLoading(false);
  }

  function showSnackbar(
    message: string | undefined,
    variant: "error" | "success" = "error"
  ) {
    if (message) enqueueSnackbar(message, { variant: variant });
  }

  return { login, isLoading, setIsLoading };
}
