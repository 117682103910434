import { AndroidTVMessagesRepository } from "../../../../data/repository/androidTVMessages/AndroidTVMessagesRepository";
import { AndroidTVMessage } from "../../../model/AndroidTVMessage";
import { ResponseState, State } from "../../../model/ResponseState";

export interface GetAllAndroidTVMessagesUseCase {
  invoke: () => Promise<ResponseState<Array<AndroidTVMessage>>>;
}

export class GetAllAndroidTVMessagesUseCaseImpl
  implements GetAllAndroidTVMessagesUseCase
{
  private androidTVMessagesRepository: AndroidTVMessagesRepository;

  constructor(androidTVMessagesRepository: AndroidTVMessagesRepository) {
    this.androidTVMessagesRepository = androidTVMessagesRepository;
  }

  async invoke(): Promise<ResponseState<Array<AndroidTVMessage>>> {
    try {
      let response = await this.androidTVMessagesRepository.getAllMessages();
      if (response.isSuccessful) {
        return {
          responseState: State.Success,
          data: response.body!,
        };
      }
      return { responseState: State.Fail, error: response.errorBody! };
    } catch (error) {
      return {
        responseState: State.Error,
        error: { message: String(error), statusCode: 500 },
      };
    }
  }
}
