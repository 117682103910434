import {
  AppBar,
  Typography,
  Toolbar,
  IconButton,
  Popover,
} from "@mui/material";
import { FIGTREE_FONT_FAMILY } from "../../data/constant/Constants";
import SharedPreferences from "../../core/utils/SharedPreferences";
import { Lock, Logout } from "@mui/icons-material";
import { STRINGS_NUB_VISION_2 } from "../../data/strings/Strings";
import { COLOR_BLACK } from "../../data/colors/Colors";
import { useState } from "react";
import { ChangePasswordView } from "../pages/changePassword/ChangePasswordView";

export function AppToolbar() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "popover" : undefined;
  return (
    <AppBar position="static">
      <Toolbar sx={{ background: COLOR_BLACK }}>
        <Typography
          variant="h5"
          component="div"
          fontWeight={"600"}
          sx={{ flexGrow: 1 }}
          fontFamily={FIGTREE_FONT_FAMILY}
        >
          {STRINGS_NUB_VISION_2}
        </Typography>
        <IconButton
          color="inherit"
          size="large"
          aria-describedby={id}
          onClick={handleClick}
        >
          <Lock />
        </IconButton>
        <IconButton
          color="inherit"
          size="large"
          onClick={() => {
            SharedPreferences.clear();
          }}
        >
          <Logout />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          PaperProps={{ sx: { borderRadius: 6 } }}
          elevation={12}
        >
          <ChangePasswordView handleClosePopover={handleClose} />
        </Popover>
      </Toolbar>
    </AppBar>
  );
}
