import { NubVisionAppsRepository } from "../../../../data/repository/nubVisionApps/NubVisionAppsRepository";
import { Message } from "../../../model/Auth";
import { NubVisionApp } from "../../../model/NubVisionApp";
import { ResponseState, State } from "../../../model/ResponseState";

export interface AddNubVisionAppUseCase {
  invoke: (nubVisionApp: NubVisionApp) => Promise<ResponseState<Message>>;
}

export class AddNubVisionAppUseCaseImpl implements AddNubVisionAppUseCase {
  private nubVisionAppsRepository: NubVisionAppsRepository;

  constructor(nubVisionAppsRepository: NubVisionAppsRepository) {
    this.nubVisionAppsRepository = nubVisionAppsRepository;
  }

  async invoke(nubVisionApp: NubVisionApp): Promise<ResponseState<Message>> {
    try {
      let response = await this.nubVisionAppsRepository.add(nubVisionApp);
      if (response.isSuccessful) {
        return {
          responseState: State.Success,
          data: response.body!,
        };
      }
      return { responseState: State.Fail, error: response.errorBody! };
    } catch (error) {
      return {
        responseState: State.Error,
        error: { message: String(error), statusCode: 500 },
      };
    }
  }
}
