import { Box } from "@mui/material";
import { AppToolbar } from "../../component/Toolbar";
import { SectionsView } from "../section/SectionsView";

export default function HomeView() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppToolbar />
      <SectionsView />
    </Box>
  );
}
