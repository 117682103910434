import { useState } from "react";
import { SendAndroidTVMessageUseCase } from "../../../domain/usecase/androidTVMessages/sendMessage/SendMessageUseCase";
import { useSnackbar } from "notistack";
import { State } from "../../../domain/model/ResponseState";

export function SendMessageViewModel(
  sendAndroidTVMessageUseCase: SendAndroidTVMessageUseCase
) {
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  async function sendMessage(e: any) {
    e.preventDefault();
    setIsLoading(true);
    const response = await sendAndroidTVMessageUseCase.invoke({
      message: message,
    });
    switch (response.responseState) {
      case State.Success:
        showSnackbar(response.data?.message, "success");
        setMessage("");
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }
    setIsLoading(false);
  }

  function showSnackbar(
    message: string | undefined,
    variant: "error" | "success" | "info" = "error"
  ) {
    if (message) enqueueSnackbar(message, { variant: variant });
  }

  return { message, setMessage, isLoading, sendMessage };
}
