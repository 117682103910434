import { AndroidTVSettingsRepository } from "../../../../data/repository/androidTVSettings/AndroidTVSettingsRepository";
import { AndroidTvSettings } from "../../../model/HomeButton";
import { ResponseState, State } from "../../../model/ResponseState";

export interface GetAndroidTVSettingsUseCase {
  invoke: () => Promise<ResponseState<AndroidTvSettings>>;
}

export class GetAndroidTVSettingsUseCaseImpl
  implements GetAndroidTVSettingsUseCase
{
  private androidTVSettingsRepository: AndroidTVSettingsRepository;

  constructor(androidTVSettingsRepository: AndroidTVSettingsRepository) {
    this.androidTVSettingsRepository = androidTVSettingsRepository;
  }

  async invoke(): Promise<ResponseState<AndroidTvSettings>> {
    try {
      let response = await this.androidTVSettingsRepository.get();
      if (response.isSuccessful) {
        return {
          responseState: State.Success,
          data: response.body!,
        };
      }
      return { responseState: State.Fail, error: response.errorBody! };
    } catch (error) {
      return {
        responseState: State.Error,
        error: { message: String(error), statusCode: 500 },
      };
    }
  }
}
