import { Box, Button, Typography } from "@mui/material";
import { HomeButton } from "../../../../domain/model/HomeButton";
import { CustomImageView } from "../../../component/CustomImageView";
import { FIGTREE_FONT_FAMILY } from "../../../../data/constant/Constants";
import { COLOR_LOGIN_CARD_BLACK } from "../../../../data/colors/Colors";
import { NubVisionApp } from "../../../../domain/model/NubVisionApp";

interface props {
  nubVisionApp: NubVisionApp;
  handleOnclick: (homeButton: HomeButton) => void;
}
export function NubVisionAppItemView(props: props) {
  return (
    <Box>
      <Button
        onClick={() => props.handleOnclick(props.nubVisionApp)}
        sx={{
          borderRadius: 6,
          padding: 0,
          transition: "transform 0.4s ease-in-out",
          "&:hover": { transform: "scale3d(1.08, 1.08, 1)", zIndex: "1" },
          position: "relative",
        }}
      >
        <CustomImageView
          width={"25vh"}
          height={"25vh"}
          props={{
            borderRadius: 6,
            border: "2px solid white",
          }}
          src={
            props.nubVisionApp.image ??
            require("../../../../assets/images/placeholder.png")
          }
        />
        <Box
          position={"absolute"}
          width={"80%"}
          top={"5%"}
          left={"10%"}
          borderRadius={6}
          sx={{
            background: COLOR_LOGIN_CARD_BLACK,
          }}
        >
          <Typography
            textAlign={"center"}
            color={"white"}
            variant="h4"
            fontFamily={FIGTREE_FONT_FAMILY}
            whiteSpace={"nowrap"}
            maxWidth={"300px"}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
            textTransform={"none"}
          >
            {props.nubVisionApp.title}
          </Typography>
        </Box>
      </Button>
    </Box>
  );
}
