import { Button, Typography } from "@mui/material";
import { FIGTREE_FONT_FAMILY } from "../../data/constant/Constants";
import { SectionView } from "./Section";
import {
  COLOR_BLACK,
  COLOR_LIGHT_GRAY,
  COLOR_SECTION_GRAY,
  COLOR_WHITE,
} from "../../data/colors/Colors";

interface Section {
  title: string;
  subtitle: string;
  buttonTitle: string;
  Onclick: () => void;
}

export function HomeSection(section: Section) {
  return (
    <SectionView
      title={section.title}
      subtitle={section.subtitle}
      minHeight="28vh"
      layout={
        <Button
          variant="outlined"
          sx={{
            marginTop: 8,
            borderRadius: 12,
            borderColor: COLOR_BLACK,
            "&:hover": {
              backgroundColor: COLOR_SECTION_GRAY,
              borderColor: COLOR_BLACK,
            },
            ".MuiTouchRipple-child": {
              backgroundColor: COLOR_LIGHT_GRAY,
            },
          }}
          onClick={section.Onclick}
        >
          <Typography
            variant="h6"
            fontFamily={FIGTREE_FONT_FAMILY}
            fontWeight={"100"}
            color={COLOR_BLACK}
            sx={{ textTransform: "none" }}
          >
            {section.buttonTitle}
          </Typography>
        </Button>
      }
    />
  );
}
