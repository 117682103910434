import { useEffect, useState } from "react";
import {
  AndroidTvSettings,
  HomeButton,
} from "../../../domain/model/HomeButton";
import { GetAndroidTVSettingsUseCase } from "../../../domain/usecase/androidTVSettings/get/GetAndroidTVSettingsUseCase";
import { State } from "../../../domain/model/ResponseState";
import { useSnackbar } from "notistack";
import { AndroidTVSettingsChangeTitleUseCase } from "../../../domain/usecase/androidTVSettings/changeTitle/ChangeTitleUseCase";
import { STRINGS_TITLE_CANNOT_BE_EMPTY } from "../../../data/strings/Strings";

export function PreviewViewModel(
  getAndroidTvSettingsUseCase: GetAndroidTVSettingsUseCase,
  androidTVSettingsChangeTitleUseCase: AndroidTVSettingsChangeTitleUseCase
) {
  const { enqueueSnackbar } = useSnackbar();
  const [androidTvSettings, setAndroidTvSettings] = useState<
    AndroidTvSettings | undefined
  >();
  const [pairedHomeButtons, setPairedHomeButtons] = useState<
    [HomeButton, HomeButton | null][] | null
  >();
  const [isLoading, setLoading] = useState(false);

  const [title, setTitle] = useState<string | null>();

  useEffect(() => {
    getAndroidTvSettings();
  }, []);

  async function getAndroidTvSettings() {
    setLoading(true);

    const response = await getAndroidTvSettingsUseCase.invoke();
    switch (response.responseState) {
      case State.Success:
        setAndroidTvSettings(response.data);
        makePairsOfHomeButtons(response.data);
        setTitle(response.data?.title);
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }
    setLoading(false);
  }

  async function changeAndroidTvSettingsTitle() {
    if (!title || title.length == 0) {
      showSnackbar(STRINGS_TITLE_CANNOT_BE_EMPTY);
      return;
    }
    const response = await androidTVSettingsChangeTitleUseCase.invoke({
      title: title,
    });
    switch (response.responseState) {
      case State.Success:
        showSnackbar(response.data?.message, "success");
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }
  }

  function makePairsOfHomeButtons(
    androidTvSettings: AndroidTvSettings | undefined
  ) {
    if (!androidTvSettings?.homeButtons) return;
    const paired = androidTvSettings?.homeButtons
      .slice(1)
      .reduce<[HomeButton, HomeButton | null][]>(
        (accumulator, currentValue, currentIndex, array) => {
          if (currentIndex % 2 === 0) {
            accumulator.push([currentValue, array[currentIndex + 1] || null]);
          }
          return accumulator;
        },
        []
      );

    setPairedHomeButtons(paired);
  }

  function showSnackbar(
    message: string | undefined,
    variant: "error" | "success" = "error"
  ) {
    if (message) enqueueSnackbar(message, { variant: variant });
  }

  return { androidTvSettings, pairedHomeButtons, isLoading, title, setTitle, changeAndroidTvSettingsTitle };
}
