import { useEffect, useState } from "react";
import { GetAllAndroidTVMessagesUseCase } from "../../../../domain/usecase/androidTVMessages/getAllMessages/GetAllMessagesUseCase";
import { State } from "../../../../domain/model/ResponseState";
import { useSnackbar } from "notistack";
import { AndroidTVMessage } from "../../../../domain/model/AndroidTVMessage";

export function PreviousMessagesViewModel(
  getAllAndroidTVMessagesUseCase: GetAllAndroidTVMessagesUseCase
) {
  const [isLoading, setIsLoading] = useState(true);
  const [messages, SetMessages] = useState<Array<AndroidTVMessage>>([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getAllMessages();
  }, []);

  async function getAllMessages() {
    setIsLoading(true);
    const response = await getAllAndroidTVMessagesUseCase.invoke();
    switch (response.responseState) {
      case State.Success:
        if (response.data) SetMessages(response.data);
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }
    setIsLoading(false);
  }

  function showSnackbar(
    message: string | undefined,
    variant: "error" | "success" = "error"
  ) {
    if (message) enqueueSnackbar(message, { variant: variant });
  }

  return { messages, isLoading };
}
