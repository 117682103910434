import { CurrentVersion, Message, Title, Url } from "../../../domain/model/Auth";
import {
  AndroidTvSettings,
  HomeButton,
} from "../../../domain/model/HomeButton";
import { Response } from "../../api/Response";
import NetworkApi from "../../network/NetworkApi";
import { AndroidTVSettingsRepository } from "./AndroidTVSettingsRepository";

export class AndroidTVSettingsRepositoryImpl
  implements AndroidTVSettingsRepository
{
  apiService: NetworkApi;

  constructor(datasource: NetworkApi) {
    this.apiService = datasource;
  }
  get(): Promise<Response<AndroidTvSettings>> {
    return this.apiService.getAndroidTVSettings();
  }
  changeBackgroundImage(url: Url): Promise<Response<Message>> {
    return this.apiService.androidTVSettingsChangeBackgroundImage(url);
  }
  changeTitle(title: Title): Promise<Response<Message>> {
    return this.apiService.androidTVSettingsChangeTitle(title);
  }
  updateCurrentLauncherAppVersion(version: CurrentVersion): Promise<Response<Message>> {
    return this.apiService.androidTVSettingUpdateCurrentLauncherAppVersion(version);
  }
  addHomeButton(homeButton: HomeButton): Promise<Response<Message>> {
    return this.apiService.androidTVSettingsAddHomeButton(homeButton);
  }
  deleteHomeButton(id: string): Promise<Response<Message>> {
    return this.apiService.androidTVSettingsDeleteHomeButton(id);
  }
  updateHomeButton(homeButton: HomeButton): Promise<Response<Message>> {
    return this.apiService.androidTVSettingsUpdateHomeButton(homeButton);
  }
}
