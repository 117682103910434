import { AuthRepository } from "../../../../data/repository/auth/AuthRepository";
import { AccessToken, Auth } from "../../../model/Auth";
import { ResponseState, State } from "../../../model/ResponseState";

export interface LoginUseCase {
  invoke: (auth: Auth) => Promise<ResponseState<AccessToken>>;
}

export class LoginUseCaseImpl implements LoginUseCase {
  private authRepository: AuthRepository;

  constructor(authRepository: AuthRepository) {
    this.authRepository = authRepository;
  }

  async invoke(auth: Auth): Promise<ResponseState<AccessToken>> {
    try {
      let response = await this.authRepository.login(auth);
      if (response.isSuccessful) {
        return {
          responseState: State.Success,
          data: response.body!,
        };
      }
      return { responseState: State.Fail, error: response.errorBody! };
    } catch (error) {
      return {
        responseState: State.Error,
        error: { message: String(error), statusCode: 500 },
      };
    }
  }
}
