export interface HomeButton {
  _id?: string;
  title?: string;
  image?: string;
  imageFile?: File;
  action?: HomeButtonAction;
  url?: string;
  packageId?: string;
  appApkDownloadUrl?: string;
}

export interface AndroidTvSettings {
  _id: string;
  backgroundImage?: string;
  title?: string;
  homeButtons?: Array<HomeButton>;
  currentLauncherAppVersion?: string;
}

export enum HomeButtonAction {
  CUSTOM_APP = "Custom App",
  DEVICE_APPS_SCREEN = "Device Apps Screen",
  DEVICE_SETTINGS = "Device Settings",
  URL_REDIRECT = "URL Redirect",
}

export const sampleAndroidTvSettings: AndroidTvSettings = {
  _id: "1",
  backgroundImage: "https://images8.alphacoders.com/133/1338579.png",
  homeButtons: [
    {
      _id: "1",
      title: "Title 1",
      image:
        "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/ff5db77d-8078-4e30-b33b-1af4bd776e89/decomno-187d4a21-f2d1-4b57-ae66-8040cd23b01e.png/v1/fill/w_1192,h_670,q_70,strp/speedpainting___random_landscape_by_waltjan_decomno-pre.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9NzIwIiwicGF0aCI6IlwvZlwvZmY1ZGI3N2QtODA3OC00ZTMwLWIzM2ItMWFmNGJkNzc2ZTg5XC9kZWNvbW5vLTE4N2Q0YTIxLWYyZDEtNGI1Ny1hZTY2LTgwNDBjZDIzYjAxZS5wbmciLCJ3aWR0aCI6Ijw9MTI4MCJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl19.vZKoyTD7urPQBHiteKm6nZ40PpDf5LMYrnvLiNg8J8U",
      action: HomeButtonAction.DEVICE_SETTINGS,
    },
    {
      _id: "2",
      title: "Title 2",
      image:
        "https://cdna.artstation.com/p/assets/images/images/043/365/940/large/breedermaniac-ce5870cf2c824a9e8c79983fcf51-hires.jpg?1637250078",
      action: HomeButtonAction.DEVICE_APPS_SCREEN,
    },
    {
      _id: "3",
      title: "Title 3",
      image:
        "https://earthwatcher.photo.blog/wp-content/uploads/2019/07/grand-tetons-at-twilight-from-schwabachers-landing.jpg",
      action: HomeButtonAction.CUSTOM_APP,
      appApkDownloadUrl: "someshit",
      packageId: "someshit.cs.c",
    },
    {
      _id: "1",
      title: "Title 1",
      image:
        "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/ff5db77d-8078-4e30-b33b-1af4bd776e89/decomno-187d4a21-f2d1-4b57-ae66-8040cd23b01e.png/v1/fill/w_1192,h_670,q_70,strp/speedpainting___random_landscape_by_waltjan_decomno-pre.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9NzIwIiwicGF0aCI6IlwvZlwvZmY1ZGI3N2QtODA3OC00ZTMwLWIzM2ItMWFmNGJkNzc2ZTg5XC9kZWNvbW5vLTE4N2Q0YTIxLWYyZDEtNGI1Ny1hZTY2LTgwNDBjZDIzYjAxZS5wbmciLCJ3aWR0aCI6Ijw9MTI4MCJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl19.vZKoyTD7urPQBHiteKm6nZ40PpDf5LMYrnvLiNg8J8U",
      action: HomeButtonAction.URL_REDIRECT,
      url: "sadas",
    },
    {
      _id: "2",
      title: "Title 2",
      image:
        "https://cdna.artstation.com/p/assets/images/images/043/365/940/large/breedermaniac-ce5870cf2c824a9e8c79983fcf51-hires.jpg?1637250078",
      action: HomeButtonAction.DEVICE_APPS_SCREEN,
    },
    {
      _id: "3",
      title: "Title 3",
      image:
        "https://earthwatcher.photo.blog/wp-content/uploads/2019/07/grand-tetons-at-twilight-from-schwabachers-landing.jpg",
      action: HomeButtonAction.DEVICE_APPS_SCREEN,
    },
    {
      _id: "1",
      title: "Title 1",
      image:
        "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/ff5db77d-8078-4e30-b33b-1af4bd776e89/decomno-187d4a21-f2d1-4b57-ae66-8040cd23b01e.png/v1/fill/w_1192,h_670,q_70,strp/speedpainting___random_landscape_by_waltjan_decomno-pre.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9NzIwIiwicGF0aCI6IlwvZlwvZmY1ZGI3N2QtODA3OC00ZTMwLWIzM2ItMWFmNGJkNzc2ZTg5XC9kZWNvbW5vLTE4N2Q0YTIxLWYyZDEtNGI1Ny1hZTY2LTgwNDBjZDIzYjAxZS5wbmciLCJ3aWR0aCI6Ijw9MTI4MCJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl19.vZKoyTD7urPQBHiteKm6nZ40PpDf5LMYrnvLiNg8J8U",
      action: HomeButtonAction.DEVICE_SETTINGS,
    },
    {
      _id: "2",
      title: "Title 2",
      image:
        "https://cdna.artstation.com/p/assets/images/images/043/365/940/large/breedermaniac-ce5870cf2c824a9e8c79983fcf51-hires.jpg?1637250078",
      action: HomeButtonAction.DEVICE_APPS_SCREEN,
    },
    {
      _id: "3",
      title: "Title 3",
      image:
        "https://earthwatcher.photo.blog/wp-content/uploads/2019/07/grand-tetons-at-twilight-from-schwabachers-landing.jpg",
      action: HomeButtonAction.DEVICE_APPS_SCREEN,
    },
  ],
};
