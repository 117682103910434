import { Box, Stack } from "@mui/material";
import AutoHeightView from "../../component/AutoHeightView";
import { CustomImageView } from "../../component/CustomImageView";
import { ImagePicker } from "../../component/ImagePicker";
import { useState } from "react";
import { COLOR_BLACK, COLOR_WHITE } from "../../../data/colors/Colors";
import { isImageMainlyDark } from "../../../core/utils/Utils";

interface Props {
  handleBackgroundImageChosen: (imgFile: File) => void;
}

export function ChooseBackgroundImageView(props: Props) {
  const minHeight = "25vh";
  const maxHeight = "80vh";
  const [imagePickerButtonMoved, setImagePickerButtonMoved] = useState(false);
  const [boxHeight, setBoxHeight] = useState(minHeight);
  const [bgImage, setBgImage] = useState<string | null>(null);
  const [buttonBgColor, setButtonBgColor] = useState<string | null>(null);

  return (
    <AutoHeightView>
      <Box height={boxHeight}>
        <Box zIndex={"1"} position={"absolute"}>
          <CustomImageView
            width="100vw"
            height={maxHeight}
            src={bgImage}
            props={{ marginBottom: -2 }}
          />
        </Box>
        <Stack
          direction={"row"}
          spacing={2}
          padding={4}
          zIndex={"2"}
          position={"absolute"}
          alignItems={imagePickerButtonMoved ? "end" : "center"}
          justifyContent={"center"}
          width={"100%"}
          height={boxHeight}
        >
          <ImagePicker
            handleImagePicked={async (file: File) => {
              console.log(file);
              if (!file) return;
              setBgImage(URL.createObjectURL(file));
              setTimeout(() => {
                setBoxHeight(maxHeight);
                setTimeout(() => {
                  setImagePickerButtonMoved(true);
                }, 50);
              }, 600);

              const isDark = await isImageMainlyDark(URL.createObjectURL(file));
              if (isDark) {
                setButtonBgColor(COLOR_WHITE);
              } else {
                setButtonBgColor(COLOR_BLACK);
              }

              props.handleBackgroundImageChosen(file);
            }}
            buttonBackgroundColor={buttonBgColor}
          />
        </Stack>
      </Box>
    </AutoHeightView>
  );
}
