import { Backdrop, Box, CircularProgress } from "@mui/material";
import LoginForm from "../../component/LoginForm";
import { LoginViewModel } from "./LoginViewModel";
import { LOGIN_PAGE_BACKGROUND_IMAGE } from "../../../data/constant/Constants";
import { CustomImageView } from "../../component/CustomImageView";
import { provideLoginUseCase } from "../../di/UseCasesModule";
import { useEffect } from "react";

export default function LoginView() {
  const { login, isLoading, setIsLoading } = LoginViewModel(
    provideLoginUseCase()
  );

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
        document.body.style.overflow = "scroll"
    };
}, []);

  return (
    <Box>
      <CustomImageView
        width="100%"
        height="100%"
        src={LOGIN_PAGE_BACKGROUND_IMAGE}
        props={{
          position: "absolute",
          zIndex: "1",
          filter: "blur(10px)",
          pointerEvents: "none",
          transform: "scale(1.1)",
        }}
      />
      <Box position={"absolute"} zIndex={"2"} width={"100%"}>
        <LoginForm onSubmit={login} />
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        onClick={() => setIsLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
