import { Message, Title, Url } from "../../../domain/model/Auth";
import {
  AndroidTvSettings,
  HomeButton,
} from "../../../domain/model/HomeButton";
import { NubVisionApp } from "../../../domain/model/NubVisionApp";
import { Response } from "../../api/Response";
import NetworkApi from "../../network/NetworkApi";
import {
  NubVisionAppsRepository,
} from "./NubVisionAppsRepository";

export class NubVisionAppsRepositoryImpl implements NubVisionAppsRepository {
  apiService: NetworkApi;

  constructor(datasource: NetworkApi) {
    this.apiService = datasource;
  }
  getAll(): Promise<Response<Array<NubVisionApp>>> {
    return this.apiService.getNubVisionApps();
  }
  add(nubVisionApp: NubVisionApp): Promise<Response<Message>> {
    return this.apiService.addNubVisionApp(nubVisionApp);
  }
  delete(id: string): Promise<Response<Message>> {
    return this.apiService.deleteNubVisionApp(id);
  }
  update(nubVisionApp: NubVisionApp): Promise<Response<Message>> {
    return this.apiService.updateNubVisionApp(nubVisionApp);
  }
}
