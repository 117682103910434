import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { FIGTREE_FONT_FAMILY } from "../../../data/constant/Constants";
import { SectionView } from "../../component/Section";
import { CustomTextField } from "../addHomeButton/AddOrEditHomeButtonView";
import { ChangePasswordViewModel } from "./ChangePasswordViewModel";
import {
  STRINGS_CHANGE,
  STRINGS_CHANGE_PASSWORD,
  STRINGS_INSERT_PASSWORD,
} from "../../../data/strings/Strings";
import { provideChangePasswordUseCase } from "../../di/UseCasesModule";

interface props {
  handleClosePopover: () => void;
}

export function ChangePasswordView(props: props) {
  const viewModel = ChangePasswordViewModel(provideChangePasswordUseCase());

  return (
    <SectionView
      title={STRINGS_CHANGE_PASSWORD}
      subtitle={STRINGS_INSERT_PASSWORD}
      layout={
        <Stack spacing={2} marginTop={8}>
          <CustomTextField
            type="password"
            placeholder="Password"
            handleChange={(password) => {
              viewModel.setPassword(password);
            }}
          />
          <CustomTextField
            type="password"
            placeholder="Confirm"
            handleChange={(password) => {
              viewModel.setConfirmPassword(password);
            }}
          />
          <Button
            disabled={viewModel.isLoading}
            variant="contained"
            onClick={() => viewModel.changePassword(props.handleClosePopover)}
            sx={{ borderRadius: 12, padding: 2, height: "60px" }}
          >
            {viewModel.isLoading ? (
              <CircularProgress color="inherit" size={25} />
            ) : (
              <Typography
                fontFamily={FIGTREE_FONT_FAMILY}
                fontSize="16px"
                textTransform={"none"}
                fontWeight={"700"}
              >
                {STRINGS_CHANGE}
              </Typography>
            )}
          </Button>
        </Stack>
      }
    />
  );
}
