import lightOrDarkImage from "@check-light-or-dark/image";
import dateAndTime from "date-and-time";

export async function isImageMainlyDark(img: string): Promise<boolean> {
  return (await lightOrDarkImage({ image: img })) == "dark";
}

export function formateDate(date: Date) {
  return dateAndTime.format(new Date(date), "DD MMM YYYY hh:mm A");
}
