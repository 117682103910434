import { AndroidTVMessage } from "../../domain/model/AndroidTVMessage";
import {
  AccessToken,
  Auth,
  CurrentVersion,
  Message,
  Password,
  Title,
  Url,
} from "../../domain/model/Auth";
import { AndroidTvSettings, HomeButton } from "../../domain/model/HomeButton";
import { NubVisionApp } from "../../domain/model/NubVisionApp";
import { ApiService } from "../api/ApiService";
import { Response } from "../api/Response";
import { API_ENDPOINTS } from "../constant/ApiConstants";
import NetworkApi from "./NetworkApi";

export default class NetworkApiImpl implements NetworkApi {
  apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  login(auth: Auth): Promise<Response<AccessToken>> {
    return this.apiService.post<AccessToken>(API_ENDPOINTS.LOGIN, auth);
  }
  changePassword(password: Password): Promise<Response<Message>> {
    return this.apiService.post<Message>(
      API_ENDPOINTS.CHANGE_PASSWORD,
      password
    );
  }

  uploadImage(imageFormData: FormData): Promise<Response<Url>> {
    return this.apiService.post<Url>(API_ENDPOINTS.UPLOAD_IMAGE, imageFormData);
  }

  getAndroidTVSettings(): Promise<Response<AndroidTvSettings>> {
    return this.apiService.get<AndroidTvSettings>(
      API_ENDPOINTS.ANDROID_TV_SETTINGS
    );
  }
  androidTVSettingsChangeBackgroundImage(url: Url): Promise<Response<Message>> {
    return this.apiService.post<Message>(
      API_ENDPOINTS.ANDROID_TV_SETTINGS_CHANGE_BACKGROUND_IMAGE,
      url
    );
  }
  androidTVSettingsChangeTitle(title: Title): Promise<Response<Message>> {
    return this.apiService.post<Message>(
      API_ENDPOINTS.ANDROID_TV_SETTINGS_CHANGE_TITLE,
      title
    );
  }
  androidTVSettingUpdateCurrentLauncherAppVersion(version: CurrentVersion): Promise<Response<Message>> {
    return this.apiService.post<Message>(
      API_ENDPOINTS.ANDROID_TV_SETTINGS_UPDATE_CURRENT_LAUNCHER_APP_VERSION,
      version
    );
  }
  androidTVSettingsAddHomeButton(
    homeButton: HomeButton
  ): Promise<Response<Message>> {
    return this.apiService.post<Message>(
      API_ENDPOINTS.ANDROID_TV_SETTINGS_HOME_BUTTONS,
      homeButton
    );
  }
  androidTVSettingsDeleteHomeButton(id: string): Promise<Response<Message>> {
    return this.apiService.delete<Message>(
      `${API_ENDPOINTS.ANDROID_TV_SETTINGS_HOME_BUTTONS}/${id}`
    );
  }
  androidTVSettingsUpdateHomeButton(
    homeButton: HomeButton
  ): Promise<Response<Message>> {
    return this.apiService.put<Message>(
      `${API_ENDPOINTS.ANDROID_TV_SETTINGS_HOME_BUTTONS}/${homeButton._id}`,
      homeButton
    );
  }

  sendAndroidTVMessage(message: AndroidTVMessage): Promise<Response<Message>> {
    return this.apiService.post<Message>(
      API_ENDPOINTS.ANDROID_TV_MESSAGES,
      message
    );
  }
  getAndroidTVMessages(): Promise<Response<Array<AndroidTVMessage>>> {
    return this.apiService.get<Array<AndroidTVMessage>>(
      API_ENDPOINTS.ANDROID_TV_MESSAGES
    );
  }

  getNubVisionApps(): Promise<Response<Array<NubVisionApp>>> {
    return this.apiService.get<Array<NubVisionApp>>(
      API_ENDPOINTS.NUB_VISION_APPS
    );
  }
  addNubVisionApp(nubVisionApp: NubVisionApp): Promise<Response<Message>> {
    return this.apiService.post<Message>(
      API_ENDPOINTS.NUB_VISION_APPS,
      nubVisionApp
    );
  }
  deleteNubVisionApp(id: string): Promise<Response<Message>> {
    return this.apiService.delete<Message>(
      `${API_ENDPOINTS.NUB_VISION_APPS}/${id}`
    );
  }
  updateNubVisionApp(nubVisionApp: NubVisionApp): Promise<Response<Message>> {
    return this.apiService.put<Message>(
      `${API_ENDPOINTS.NUB_VISION_APPS}/${nubVisionApp._id}`,
      nubVisionApp
    );
  }
}
