import { AndroidTVSettingsRepository } from "../../../../data/repository/androidTVSettings/AndroidTVSettingsRepository";
import { Message } from "../../../model/Auth";
import { HomeButton } from "../../../model/HomeButton";
import { ResponseState, State } from "../../../model/ResponseState";

export interface AndroidTVSettingsAddHomeButtonUseCase {
  invoke: (homeButton: HomeButton) => Promise<ResponseState<Message>>;
}

export class AndroidTVSettingsAddHomeButtonUseCaseImpl
  implements AndroidTVSettingsAddHomeButtonUseCase
{
  private androidTVSettingsRepository: AndroidTVSettingsRepository;

  constructor(androidTVSettingsRepository: AndroidTVSettingsRepository) {
    this.androidTVSettingsRepository = androidTVSettingsRepository;
  }

  async invoke(homeButton: HomeButton): Promise<ResponseState<Message>> {
    try {
      let response = await this.androidTVSettingsRepository.addHomeButton(
        homeButton
      );
      if (response.isSuccessful) {
        return {
          responseState: State.Success,
          data: response.body!,
        };
      }
      return { responseState: State.Fail, error: response.errorBody! };
    } catch (error) {
      return {
        responseState: State.Error,
        error: { message: String(error), statusCode: 500 },
      };
    }
  }
}
