import { useEffect, useState } from "react";
import { HomeButton } from "../../../domain/model/HomeButton";
import { State } from "../../../domain/model/ResponseState";
import { useSnackbar } from "notistack";
import { GetAllNubVisionAppsUseCase } from "../../../domain/usecase/nubVisionApps/getAll/GetAllNubVisionAppsUseCase";
import { NubVisionApp } from "../../../domain/model/NubVisionApp";

export function NubVisionAppsPreviewViewModel(
  getAllNubVisionAppsUseCase: GetAllNubVisionAppsUseCase
) {
  const { enqueueSnackbar } = useSnackbar();
  const [nubVisionApps, setNubVisionApps] = useState<
    Array<NubVisionApp> | undefined
  >();
  const [pairedNubVisionApps, setPairedNubVisionApps] = useState<
    [NubVisionApp, NubVisionApp | null][] | null
  >();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    getNubVisionApps();
  }, []);

  async function getNubVisionApps() {
    setLoading(true);

    const response = await getAllNubVisionAppsUseCase.invoke();
    switch (response.responseState) {
      case State.Success:
        setNubVisionApps(response.data);
        makePairsOfHomeButtons(response.data);
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }
    setLoading(false);
  }

  function makePairsOfHomeButtons(
    nubVisionApps: Array<NubVisionApp> | undefined
  ) {
    if (!nubVisionApps) return;
    const paired = nubVisionApps.reduce<[HomeButton, HomeButton | null][]>(
      (accumulator, currentValue, currentIndex, array) => {
        if (currentIndex % 2 === 0) {
          accumulator.push([currentValue, array[currentIndex + 1] || null]);
        }
        return accumulator;
      },
      []
    );

    setPairedNubVisionApps(paired);
  }

  function showSnackbar(
    message: string | undefined,
    variant: "error" | "success" = "error"
  ) {
    if (message) enqueueSnackbar(message, { variant: variant });
  }

  return {
    nubVisionApps,
    pairedNubVisionApps,
    isLoading,
  };
}
