import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Validation } from "../../domain/validation/Validation";
import { FIGTREE_FONT_FAMILY } from "../../data/constant/Constants";
import { Card, Container, Stack, Typography } from "@mui/material";
import { STRINGS_EMAIL, STRINGS_LOGIN, STRINGS_NUB_VISION_2, STRINGS_PASSWORD } from "../../data/strings/Strings";
import { COLOR_LIGHT_GRAY, COLOR_LOGIN_CARD_BLACK, COLOR_WHITE } from "../../data/colors/Colors";

interface LoginFormProps {
  onSubmit: (email: string, password: string) => void;
}

function LoginForm({ onSubmit }: LoginFormProps) {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const errors = validateInputs();
    setErrors(errors);

    if (Object.keys(errors).length === 0) onSubmit(email, password);
  };

  const validateInputs = () => {
    const errors: { [key: string]: string } = {};
    const emailValid = Validation.validateEmail(email);
    if (emailValid !== true) {
      errors.email = "Email not valid";
    }
    if (email.length === 0) {
      errors.email = "Email is required";
    }
    if (password.length === 0) {
      errors.password = "Password is required";
    }
    return errors;
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <Container
        maxWidth="sm"
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Card
          raised
          elevation={20}
          sx={{
            flex: 1,
            padding: "8%",
            margin: "8%",
            borderRadius: 8,
            minWidth: "25vw",
            backgroundColor: COLOR_LOGIN_CARD_BLACK,
            minHeight: "70vh",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Stack spacing={12} flex={1}>
            <Typography
              variant="h3"
              fontFamily={FIGTREE_FONT_FAMILY}
              fontWeight={"700"}
              color={COLOR_WHITE}
            >
              {STRINGS_LOGIN}
            </Typography>

            <Stack spacing={6}>
              <Stack spacing={2}>
                <TextField
                  type="email"
                  placeholder={STRINGS_EMAIL}
                  variant="outlined"
                  fullWidth
                  onChange={handleEmailChange}
                  error={!!errors.email}
                  helperText={errors.email}
                  required
                  autoComplete="off"
                  InputProps={{
                    sx: {
                      borderRadius: 4,
                      fontFamily: FIGTREE_FONT_FAMILY,
                      color: "white",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: COLOR_LIGHT_GRAY,
                      },
                    },
                  }}
                />
                <TextField
                  type="password"
                  placeholder={STRINGS_PASSWORD}
                  variant="outlined"
                  onChange={handlePasswordChange}
                  error={!!errors.password}
                  helperText={errors.password}
                  required
                  fullWidth
                  InputProps={{
                    sx: {
                      borderRadius: 4,
                      fontFamily: FIGTREE_FONT_FAMILY,
                      color: "white",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: COLOR_LIGHT_GRAY,
                      },
                    },
                  }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ borderRadius: 4 }}
                >
                  <Typography
                    fontFamily={FIGTREE_FONT_FAMILY}
                    fontWeight={"bold"}
                    variant="h6"
                    padding={1}
                    sx={{ textTransform: "none" }}
                  >
                    {STRINGS_LOGIN}
                  </Typography>
                </Button>
              </Stack>
              <Typography
                variant="h6"
                fontFamily={FIGTREE_FONT_FAMILY}
                fontWeight={"300"}
                textAlign={"center"}
                color={COLOR_LIGHT_GRAY}
              >
                {STRINGS_NUB_VISION_2}
              </Typography>
            </Stack>
          </Stack>
        </Card>
      </Container>
    </Box>
  );
}

export default LoginForm;
