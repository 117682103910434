import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { COLOR_BLACK, COLOR_WHITE } from "../../../data/colors/Colors";
import { FIGTREE_FONT_FAMILY } from "../../../data/constant/Constants";
import { NubVisionAppsPreviewViewModel } from "./NubVisionAppsPreviewViewModel";
import { NubVisionAppItemView } from "./NubVisionApp/NubVisionAppView";
import { STRINGS_NUB_VISION_APPS } from "../../../data/strings/Strings";
import { provideGetAllNubVisionAppsUseCase } from "../../di/UseCasesModule";
import { NubVisionApp } from "../../../domain/model/NubVisionApp";

interface props {
  handleOnNubVisionAppClicked: (nubVisionApp: NubVisionApp) => void;
}

export function NubVisionAppsPreviewView(props: props) {
  const viewModel = NubVisionAppsPreviewViewModel(
    provideGetAllNubVisionAppsUseCase()
  );
  return (
    <Box
      paddingLeft={2}
      paddingRight={2}
      position={"relative"}
      paddingTop={2}
      marginTop={4}
      flex={1}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{ background: COLOR_BLACK, borderRadius: 6 }}
    >
      <Box
        sx={{
          position: "absolute",
          zIndex: "2",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
        }}
      >
        <Stack
          width={"100%"}
          height={"100%"}
          justifyContent={"space-between"}
          paddingTop={4}
        >
          {viewModel.isLoading ? (
            <Box
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
              width={"100%"}
              height={"100%"}
            >
              <CircularProgress size={80} color="error" />
            </Box>
          ) : (
            <>
              {viewModel.nubVisionApps &&
                viewModel.nubVisionApps.length > 0 && (
                  <>
                    <Typography
                      sx={{
                        marginLeft: 4,
                        marginBottom: -3,
                        fontFamily: FIGTREE_FONT_FAMILY,
                        color: COLOR_WHITE,
                        fontWeight: "600",
                        fontSize: "38px",
                        textTransform: "none",
                        padding: 0,
                      }}
                    >
                      {STRINGS_NUB_VISION_APPS}
                    </Typography>
                    <Box
                      display={"inline-flex"}
                      flexDirection={"row"}
                      alignItems={"center"}
                      overflow={"auto"}
                      gap={"2vh"}
                    >
                      {viewModel.pairedNubVisionApps &&
                        viewModel.pairedNubVisionApps.map(
                          (pairOfNubVisionApps, currentIndex) => {
                            if (
                              viewModel.nubVisionApps &&
                              viewModel.nubVisionApps.length > 0
                            )
                              return (
                                <Box
                                  key={currentIndex}
                                  display={"inline-flex"}
                                  alignItems={"center"}
                                  height={"100%"}
                                  gap={"3vh"}
                                  justifyContent={"space-between"}
                                  flexDirection={"column"}
                                  paddingRight={
                                    currentIndex ===
                                    viewModel.nubVisionApps?.length / 2
                                      ? 4
                                      : 0
                                  }
                                  padding={4}
                                >
                                  <NubVisionAppItemView
                                    nubVisionApp={pairOfNubVisionApps[0]}
                                    handleOnclick={
                                      props.handleOnNubVisionAppClicked
                                    }
                                  />
                                  {pairOfNubVisionApps[1] && (
                                    <NubVisionAppItemView
                                      nubVisionApp={pairOfNubVisionApps[1]}
                                      handleOnclick={
                                        props.handleOnNubVisionAppClicked
                                      }
                                    />
                                  )}
                                </Box>
                              );
                            return <></>;
                          }
                        )}
                    </Box>
                  </>
                )}
            </>
          )}
        </Stack>
      </Box>
    </Box>
  );
}
