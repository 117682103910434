import { AndroidTVSettingsRepository } from "../../../../data/repository/androidTVSettings/AndroidTVSettingsRepository";
import { Message, Url } from "../../../model/Auth";
import { ResponseState, State } from "../../../model/ResponseState";

export interface AndroidTVSettingsChangeBackgroundImageUseCase {
  invoke: (url: Url) => Promise<ResponseState<Message>>;
}

export class AndroidTVSettingsChangeBackgroundImageUseCaseImpl
  implements AndroidTVSettingsChangeBackgroundImageUseCase
{
  private androidTVSettingsRepository: AndroidTVSettingsRepository;

  constructor(androidTVSettingsRepository: AndroidTVSettingsRepository) {
    this.androidTVSettingsRepository = androidTVSettingsRepository;
  }

  async invoke(url: Url): Promise<ResponseState<Message>> {
    try {
      let response =
        await this.androidTVSettingsRepository.changeBackgroundImage(url);
      if (response.isSuccessful) {
        return {
          responseState: State.Success,
          data: response.body!,
        };
      }
      return { responseState: State.Fail, error: response.errorBody! };
    } catch (error) {
      return {
        responseState: State.Error,
        error: { message: String(error), statusCode: 500 },
      };
    }
  }
}
