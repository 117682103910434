import { AndroidTVMessage } from "../../../domain/model/AndroidTVMessage";
import { Message, Url } from "../../../domain/model/Auth";
import {
  AndroidTvSettings,
  HomeButton,
} from "../../../domain/model/HomeButton";
import { Response } from "../../api/Response";
import NetworkApi from "../../network/NetworkApi";
import { AndroidTVMessagesRepository } from "./AndroidTVMessagesRepository";

export class AndroidTVMessagesRepositoryImpl
  implements AndroidTVMessagesRepository
{
  apiService: NetworkApi;

  constructor(datasource: NetworkApi) {
    this.apiService = datasource;
  }
  getAllMessages(): Promise<Response<Array<AndroidTVMessage>>> {
    return this.apiService.getAndroidTVMessages();
  }
  sendMessage(message: AndroidTVMessage): Promise<Response<Message>> {
    return this.apiService.sendAndroidTVMessage(message);
  }
}
