import {
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { CustomImageView } from "../../component/CustomImageView";
import { ClockView } from "./ClockView";
import { COLOR_WHITE } from "../../../data/colors/Colors";
import { FIGTREE_FONT_FAMILY } from "../../../data/constant/Constants";
import { PreviewViewModel } from "./PreviewViewModel";
import { HomeButtonItemView } from "./HomeButton/HomeButtonView";
import { HomeButton } from "../../../domain/model/HomeButton";
import { STRINGS_HOME_BUTTONS } from "../../../data/strings/Strings";
import {
  provideChangeTitleUseCase,
  provideGetAndroidTvSettingsUseCase,
} from "../../di/UseCasesModule";

interface props {
  handleOnHomeButtonClicked: (homeButton: HomeButton) => void;
}

export function PreviewView(props: props) {
  const viewModel = PreviewViewModel(
    provideGetAndroidTvSettingsUseCase(),
    provideChangeTitleUseCase()
  );
  return (
    <Box
      paddingLeft={2}
      paddingRight={2}
      position={"relative"}
      paddingTop={2}
      marginTop={4}
      flex={1}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <CustomImageView
        width={"100%"}
        height={"100%"}
        props={{
          zIndex: "1",
          position: "absolute",
          borderRadius: 6,
          top: 0,
          left: 0,
          filter: "blur(6px)",
          pointerEvents: "none",
          transform: "scale(1.01)",
        }}
        src={
          (viewModel.androidTvSettings &&
            viewModel.androidTvSettings.backgroundImage) ??
          require("../../../assets/images/placeholder.png")
        }
      />
      <Box
        sx={{
          position: "absolute",
          zIndex: "2",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
        }}
      >
        <Stack
          width={"100%"}
          height={"100%"}
          justifyContent={"space-between"}
          paddingTop={4}
        >
          <Box
            justifyContent={"end"}
            flex={1}
            display={"flex"}
            paddingRight={4}
          >
            <ClockView />
          </Box>

          {viewModel.isLoading ? (
            <Box
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
              width={"100%"}
              height={"100%"}
            >
              <CircularProgress size={80} color="error" />
            </Box>
          ) : (
            <>
              {viewModel.androidTvSettings &&
                viewModel.androidTvSettings.homeButtons &&
                viewModel.androidTvSettings.homeButtons.length > 0 && (
                  <>
                    <Box
                      sx={{ marginLeft: 2, marginRight: 2, marginBottom: -3 }}
                      component={"form"}
                      noValidate
                      onSubmit={(e) => {
                        e.preventDefault();
                        viewModel.changeAndroidTvSettingsTitle();
                      }}
                    >
                      <TextField
                        variant="standard"
                        contentEditable={"false"}
                        value={viewModel.title}
                        fullWidth
                        autoComplete="off"
                        sx={{
                          "& .MuiInput-root:hover::before": {
                            borderBottomColor: "transparent",
                          },
                          "& .MuiInput-root:hover::after": {
                            borderBottomColor: "transparent",
                          },
                          "& .MuiInput-underline:before": {
                            borderBottomColor: "transparent",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: "transparent",
                          },
                          maxWidth: "50%",
                        }}
                        onChange={(e) => viewModel.setTitle(e.target.value)}
                        InputProps={{
                          sx: {
                            fontFamily: FIGTREE_FONT_FAMILY,
                            color: COLOR_WHITE,
                            fontWeight: "600",
                            fontSize: "38px",
                            textTransform: "none",
                            padding: 0,
                          },
                        }}
                      />
                    </Box>
                    <Box
                      display={"inline-flex"}
                      flexDirection={"row"}
                      alignItems={"center"}
                      overflow={"auto"}
                      gap={"1vh"}
                    >
                      <HomeButtonItemView
                        currentIndex={0}
                        homeButton={viewModel.androidTvSettings.homeButtons[0]}
                        handleOnclick={props.handleOnHomeButtonClicked}
                      />
                      {viewModel.pairedHomeButtons &&
                        viewModel.pairedHomeButtons.map(
                          (pairOfHomeButtons, currentIndex) => {
                            if (
                              viewModel.androidTvSettings &&
                              viewModel.androidTvSettings.homeButtons &&
                              viewModel.androidTvSettings.homeButtons.length > 0
                            )
                              return (
                                <Box
                                  key={currentIndex}
                                  display={"inline-flex"}
                                  alignItems={"center"}
                                  height={"100%"}
                                  justifyContent={"space-between"}
                                  flexDirection={"column"}
                                  paddingRight={
                                    currentIndex ===
                                    viewModel.androidTvSettings?.homeButtons
                                      ?.length /
                                      2
                                      ? 4
                                      : 0
                                  }
                                  paddingBottom={4}
                                  paddingTop={4}
                                >
                                  <HomeButtonItemView
                                    currentIndex={currentIndex * 2 + 1}
                                    homeButton={pairOfHomeButtons[0]}
                                    handleOnclick={
                                      props.handleOnHomeButtonClicked
                                    }
                                  />
                                  {pairOfHomeButtons[1] && (
                                    <HomeButtonItemView
                                      currentIndex={currentIndex * 2 + 2}
                                      homeButton={pairOfHomeButtons[1]}
                                      handleOnclick={
                                        props.handleOnHomeButtonClicked
                                      }
                                    />
                                  )}
                                </Box>
                              );
                            return <></>;
                          }
                        )}
                    </Box>
                  </>
                )}
            </>
          )}
        </Stack>
      </Box>
    </Box>
  );
}
