import { Box, Button, Stack, Typography } from "@mui/material";
import { FIGTREE_FONT_FAMILY } from "../../data/constant/Constants";
import { ImageOutlined } from "@mui/icons-material";
import { COLOR_WHITE_WITH_KINDA_TRANSPARENCY } from "../../data/colors/Colors";
import { STRINGS_CHOOSE_AN_IMAGE } from "../../data/strings/Strings";

interface ImagePickerInterface {
  handleImagePicked: (file: File) => void;
  buttonBackgroundColor?: string | null;
  size?: "small" | "large" | null;
}

export function ImagePicker(imagePickerInterface: ImagePickerInterface) {
  function handleChange(e: any) {
    imagePickerInterface.handleImagePicked(e.target.files[0]);
  }

  return (
    <Box>
      <Button
        sx={{
          padding: 0,
          borderRadius: 4,
          color: imagePickerInterface.buttonBackgroundColor ?? undefined,
          borderColor: imagePickerInterface.buttonBackgroundColor ?? undefined,
          background: COLOR_WHITE_WITH_KINDA_TRANSPARENCY,
          "&:hover": {
            backgroundColor: COLOR_WHITE_WITH_KINDA_TRANSPARENCY,
            borderColor:
              imagePickerInterface.buttonBackgroundColor ?? undefined,
          },
        }}
        variant="outlined"
      >
        <label htmlFor="image-picker-input">
          <Stack
            alignItems={"center"}
            padding={imagePickerInterface.size === "small" ? 2 : 4}
            spacing={1}
          >
            <ImageOutlined
              fontSize={
                imagePickerInterface.size === "small" ? "small" : "large"
              }
            />
            <Typography
              fontFamily={FIGTREE_FONT_FAMILY}
              textTransform={"none"}
              fontWeight={"600"}
              fontSize={imagePickerInterface.size === "small" ? 12 : 16}
            >
              {STRINGS_CHOOSE_AN_IMAGE}
            </Typography>
          </Stack>
        </label>
      </Button>
      <input
        id="image-picker-input"
        type="file"
        onChange={handleChange}
        accept="image/png, image/gif, image/jpeg"
        style={{ display: "none" }}
      />
    </Box>
  );
}
